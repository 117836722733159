<template>
    <b-container>
        <b-row class="center">
            <b-col cols="12" class="article">
                <h1 class="mb-5"> Blog</h1>
                <p>Ik heb ook een aantal artikelen geschreven over creatieve bezigheden van anderen. Deze wil ik hier binnenkort plaatsen.</p>
                </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {

}
</script>